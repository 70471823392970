<template>
  <div :id="chartId">
    <a-empty
      v-if="!chartData.length"
      :description="false"
      :style="{ height: height + 'px', lineHeight: height + 'px' }"
    ></a-empty>
  </div>
</template>

<script>
import { Chart } from '@antv/g2'
import { chartColors } from '@/utils/const'
import { getIntegerTicks } from '@/utils'

const chart = {}

export default {
  name: 'LineChart',
  props: {
    chartData: {
      type: Array
    },
    chartId: {
      type: String,
      required: true
    },
    colors: {
      type: [Array, Function],
      default: () => chartColors
    },
    height: {
      type: Number,
      default: 200
    },
    legendPosition: {
      type: String,
      default: 'bottom'
    },
    showArea: {
      type: Boolean,
      default: false
    },
    showLegend: {
      type: Boolean,
      default: true
    },
    x: {
      type: String,
      default: 'date'
    },
    y: {
      type: String,
      default: 'value'
    },
    colorType: {
      type: String,
      default: 'value'
    },
    showDot: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    chart[this.chartId] = new Chart({
      container: this.chartId,
      autoFit: true,
      height: this.height
    })
    chart[this.chartId].scale(this.y, {
      min: 0,
      nice: true,
      tickMethod: getIntegerTicks
    })
    chart[this.chartId].axis(this.x, {
      tickLine: null,
      line: null,
      grid: null,
      label: {
        style: {
          fill: '#808080'
        }
      }
    })
    chart[this.chartId].axis(this.y, {
      grid: {
        line: {
          style: {
            stroke: '#cccccc',
            strokeOpacity: 0.8,
            lineWidth: 1,
            lineDash: [4]
          }
        }
      },
      label: {
        style: {
          fill: '#808080'
        }
      }
    })
    chart[this.chartId].tooltip({
      shared: true,
      showCrosshairs: true
    })
    if (this.showLegend) {
      chart[this.chartId].legend({
        position: this.legendPosition,
        offsetY: 12
      })
    } else {
      chart[this.chartId].legend(false)
    }
    chart[this.chartId].scale(this.x, {
      range: [0, 1]
    })
    if (this.showArea) {
      chart[this.chartId]
        .area()
        .position(`${this.x}*${this.y}`)
        .shape('smooth')
        .color(this.colorType, this.colors.map(color => `l(90) 0:${color} 1:${color}00`))
        .tooltip(false)
    }
    chart[this.chartId]
      .line()
      .position(`${this.x}*${this.y}`)
      .shape('smooth')
      .color(this.colorType, this.colors)
    if (this.showDot) {
      chart[this.chartId]
        .point()
        .position(`${this.x}*${this.y}`)
        .shape('circle')
        .color(this.colorType, this.colors)
    }
    this.render()
  },
  methods: {
    render () {
      chart[this.chartId].changeData(this.chartData)
    }
  },
  watch: {
    chartData () {
      this.render()
    }
  }
}
</script>
