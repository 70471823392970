<template>
  <div class="become-due-container">
    <a-spin :spinning="exportSpinning" tip="导出中，请稍后...">
      <a-row :gutter="16" id="pdfDom" style="padding-top: 16px;">
        <a-col  :xl="8" :lg="12" :md="24" style="margin-bottom: 16px;">
          <div style="background: #fff; border-radius: 8px; padding: 16px; height: 347px; box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;">
            <div class="module-header" style="margin-bottom: 16px">基本概况</div>

            <a-col :span="12" style="margin-bottom: 16px;">
              <div class="small-info-container red">
                <div style="margin-right: 6px;">
                  <become-due-icon style="font-size: 40px;"></become-due-icon>
                </div>
                <div>
                  <div class="title">已到期设备</div>
                  <div class="value">{{ countData['expired'] }}</div>
                </div>
              </div>
            </a-col>
            <a-col :span="12" style="margin-bottom: 16px;">
              <div class="small-info-container orange">
                <div style="margin-right: 6px;">
                  <become-due-icon style="font-size: 40px;"></become-due-icon>
                </div>
                <div>
                  <div class="title">1月内到期设备</div>
                  <div class="value">{{ countData['expires_in_1m'] }}</div>
                </div>
              </div>
            </a-col>
            <a-col :span="12" style="margin-bottom: 16px;">
              <div class="small-info-container yellow">
                <div style="margin-right: 6px;">
                  <become-due-icon style="font-size: 40px;"></become-due-icon>
                </div>
                <div>
                  <div class="title">3月内到期设备</div>
                  <div class="value">{{ countData['expires_in_3m'] }}</div>
                </div>
              </div>
            </a-col>
          </div>
        </a-col>
        <a-col  :xl="16" :lg="24" style="margin-bottom: 16px;">
          <div style="background: #fff; border-radius: 8px; padding: 16px; min-height: 347px;overflow: hidden; box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;">
            <div class="module-header" style="margin-bottom: 16px">设备到期概况</div>

            <a-col :xl="10" :lg="12" :sm="24" style="margin-bottom: 16px;">
              <header class="source-become-due-header">到期设备类型分布</header>
              <div style="border-right: 1px solid #DBDBDB; margin: 8px 0 0 0;">
                <radial-bar-chart
                  chart-id="source-type-become-due-chart"
                  :chart-data="chartData"
                  legendPosition="right"
                  :flip-page=false
                  :maxValue="null"
                  :height="228"
                  unit=""
                  :style="{paddingTop: chartData.length ? '30px' : '40px', maxHeight: '228px', overflow: 'hidden'}"
                ></radial-bar-chart>
              </div>
            </a-col>

            <a-col :xl="14" :lg="12" :sm="24" style="margin-bottom: 16px;">
              <header class="source-become-due-header">到期设备时间分布</header>
              <div style="margin: 8px 0 0 0; overflow: hidden; height: 228px;">
                <line-chart
                  chart-id="source-type-become-due-line-chart"
                  :chart-data="lineChartData"
                  :colors="['#FF5B73', '#FA6400', '#F7B500']"
                  x="name"
                  colorType="type"
                  legendPosition="top"
                  :height="228"
                  :showArea="true"
                  :showDot="false"
                ></line-chart>
              </div>
            </a-col>
          </div>
        </a-col>
        <a-col :span="24" style="margin-bottom: 16px;">
          <div style="background: #fff; border-radius: 8px; padding: 16px; box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;">
            <div class="module-header" style="margin-bottom: 16px">设备到期详情</div>
            <list-layout
              :column-default-selected="columnDefaultSelected"
              :columns-all="columnsAll"
              :get-table-data-func="getTableDataFunc"
              :table-operation-width="0"
              :tools-list="exportSpinning ? [] : ['setting', 'leftTool']"
              :show-pagination="!exportSpinning"
              :canSelect="false"
              :customer-params="customerParams"
              rowKey="name"
              @tableDataTotal="v => total = v"
              @tableLoading="fetchReady"
            >
              <template slot="leftTool">
                <a-space size="large" class="table-tools-container">
                  <a-space>
                    <span style="white-space: nowrap;">资源类型:</span>
                    <simple-select
                      :options="sourceTypeOptions"
                      v-model="currentSourceType"
                      :width="160"
                    ></simple-select>
                  </a-space>
                  <a-space>
                    <span style="white-space: nowrap;">名称:</span>
                    <a-input
                      placeholder="请输入搜索关键字"
                      v-model="nameSearchText"
                    ></a-input>
                  </a-space>
                  <a-space>
                    <span style="white-space: nowrap;">IP:</span>
                    <a-input
                      placeholder="请输入搜索关键字"
                      v-model="ipSearchText"
                    ></a-input>
                  </a-space>
                  <a-button type="primary" @click="search">查询</a-button>
                  <a-button type="default" @click="reset">重置</a-button>
                  <a-dropdown>
                    <a-button type="default" icon="export" @click="e => e.preventDefault()">导出</a-button>
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <a href="javascript:;" @click="handleExport">导出当前</a>
                      </a-menu-item>
                      <a-menu-item>
                        <a href="javascript:;" @click="handleExportAll">导出全部</a>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </a-space>
              </template>
              <template #sourceType="{ slotProps }">
                {{ $t(`source_type.${slotProps.text}`) }}
              </template>
            </list-layout>
          </div>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import { getExpirationList, getExpirationStatistic } from '@/api/expiration.js'
import { assetTypes, expirationTime } from '@/utils/const'
import BecomeDueIcon from '@/components/icon/BecomeDueIcon'
import RadialBarChart from '@/components/chart/RadialBarChart'
import LineChart from '@/components/chart/LineChart'
import SimpleSelect from '@/components/select/SimpleSelect'
import ListLayout from '@/components/ListLayout'
import PdfLoader from '@/utils/htmlToPdf'

export default {
  name: 'Expiration',
  components: {
    BecomeDueIcon,
    RadialBarChart,
    LineChart,
    SimpleSelect,
    ListLayout
  },
  data () {
    return {
      exportSpinning: false,
      getTableDataFunc: getExpirationList,
      sourceTypeOptions: [
        { label: '全部资源', value: 'all' },
        ...assetTypes.map(item => {
          return { label: this.$t(`source_type.${item}`), value: item }
        })
      ],
      currentSourceType: { label: '全部资源', value: 'all' },
      nameSearchText: '',
      ipSearchText: '',
      columnsAll: [
        {
          dataIndex: 'name',
          title: '设备名称',
          width: 200,
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          }
        },
        {
          dataIndex: 'source_type',
          title: '设备类型',
          width: 200,
          scopedSlots: {
            customRender: 'sourceType'
          }
        },
        {
          dataIndex: 'monitor_address',
          title: 'IP 地址',
          width: 160
        },
        {
          dataIndex: 'purchased_at',
          title: '采购时间',
          width: 200,
          scopedSlots: {
            customRender: 'purchasedAt'
          }
        },
        {
          dataIndex: 'expires_at',
          title: '到期时间',
          width: 200,
          scopedSlots: {
            customRender: 'expiresAt'
          }
        },
        {
          dataIndex: 'manager',
          title: '负责人',
          width: 200,
          scopedSlots: {
            customRender: 'manager'
          }
        },
        {
          dataIndex: 'location',
          title: '位置',
          width: 200,
          scopedSlots: {
            customRender: 'location'
          }
        }
      ],
      customerParams: {},
      columnDefaultSelected: [
        'name',
        'source_type',
        'monitor_address',
        'purchased_at',
        'expires_at',
        'manager',
        'location'
      ],
      chartData: [],
      total: 0,
      lineChartData: [],
      countData: {
        expired: 0,
        expires_in_1m: 0,
        expires_in_3m: 0
      },
      statisticData: {},
      expirationTime
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      getExpirationStatistic().then(res => {
        this.statisticData = res.data.data
        if (this.statisticData) {
          for (const key in this.statisticData) {
            if (Object.hasOwnProperty.call(this.statisticData, key)) {
              const valObj = this.statisticData[key]
              const sourceTypeName = this.$t(`source_type.${key}`)
              let simpleTotal = 0
              this.expirationTime.forEach(time => {
                simpleTotal += valObj[time]
                this.lineChartData.push({
                  name: sourceTypeName,
                  type: this.$t(`expiration_time.${time}`),
                  value: valObj[time]
                })
                this.countData[time] = this.countData[time] + valObj[time]
              })
              this.chartData.push({
                name: sourceTypeName,
                value: simpleTotal
              })
            }
          }
        }
      })
    },
    search () {
      const obj = {}
      if (this.nameSearchText) obj.name = this.nameSearchText
      if (this.ipSearchText) obj.monitor_address = this.ipSearchText
      if (this.currentSourceType.value !== 'all') obj.source_type = this.currentSourceType.value
      this.customerParams = obj
    },
    reset () {
      this.currentSourceType = { label: '全部资源', value: 'all' }
      this.nameSearchText = ''
      this.ipSearchText = ''
      this.exportSpinning = false
      this.total = 0
    },
    handleExport (exportAll = false) {
      this.exportSpinning = true
      this.$nextTick(() => {
        const ele = document.getElementById('pdfDom')
        const pdf = new PdfLoader(ele, '设备到期报表', 'pdf-split-flag')
        pdf.outPdf().then(() => {
          this.exportSpinning = false
          if (exportAll) {
            this.customerParams = {
              page: 1,
              page_size: 20
            }
          }
        })
      })
    },
    handleExportAll () {
      this.exportSpinning = true
      this.currentSourceType = { label: '全部资源', value: 'all' }
      this.nameSearchText = ''
      this.ipSearchText = ''
      this.customerParams = {
        page: 1,
        page_size: 9999
      }
    },
    fetchReady () {
      if (this.exportSpinning) {
        setTimeout(() => {
          this.handleExport(true)
        }, 100)
      }
    }
  }
}
</script>

<style lang="less">
.become-due-container {
  margin-top: -16px;
  .source-become-due-header {
      font-size: 16px;
      font-weight: 500;
      color: #096dd9;
      line-height: 22px;
    }
  .ant-col {
    .red {
      background: #FFF5F7;
      color: #FF5B73;
    }
    .orange {
      background: #FFF6F0;
      color: #FA6400;
    }
    .yellow {
      background: #FFFBF0;
      color: #F7B500;
    }
    .small-info-container {
      height: 128px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      border-radius: 8px;
      padding: 8px;
      justify-content: space-evenly;

      .title {
        font-size: 16px;
        color: rgba(0,0,0,0.85);
        line-height: 22px;
      }
      .value {
        font-size: 26px;
        font-weight: 500;
        line-height: 37px;
      }
    }
  }
}
</style>
