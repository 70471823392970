import request from '@/utils/request'

const urlPrefix = '/expirations'

export function getExpirationList (params) {
  return request.get(urlPrefix, { params })
}

export function getExpirationStatistic (params) {
  return request.get(`${urlPrefix}/statistic`, { params })
}
