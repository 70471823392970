<template>
  <a-icon :component="component"></a-icon>
</template>

<script>
export default {
  name: 'BecomeDueIcon',
  data () {
    return {
      component: {
        template: `
          <svg viewBox="0 0 1024 1024" width="1em" height="1em">
              <path d="M453.290667 989.866667H181.76c-55.466667 0-100.522667-45.056-100.522667-100.522667V134.656C81.066667 79.189333 126.122667 34.133333 181.76 34.133333h576.853333c55.466667 0 100.522667 45.056 100.522667 100.522667v305.322667c0 18.602667-15.018667 33.450667-33.450667 33.450666-18.602667 0-33.450667-15.018667-33.450666-33.450666V134.656c0-18.432-15.018667-33.450667-33.450667-33.450667h-576.853333c-18.432 0-33.450667 15.018667-33.450667 33.450667V889.173333c0 18.432 15.018667 33.450667 33.450667 33.450667h271.701333c18.602667 0 33.450667 15.018667 33.450667 33.450667S471.893333 989.866667 453.290667 989.866667z" fill="currentColor" p-id="10545"></path><path d="M480.938667 272.554667H258.901333c-18.602667 0-33.450667-15.018667-33.450666-33.450667s15.018667-33.450667 33.450666-33.450667h222.208c18.602667 0 33.450667 15.018667 33.450667 33.450667s-15.018667 33.450667-33.621333 33.450667zM614.229333 405.845333H258.901333c-18.602667 0-33.450667-15.018667-33.450666-33.450666 0-18.602667 15.018667-33.450667 33.450666-33.450667H614.4c18.602667 0 33.450667 15.018667 33.450667 33.450667s-15.018667 33.450667-33.621334 33.450666zM694.784 493.568c-136.874667 0-248.149333 111.274667-248.149333 248.149333S557.909333 989.866667 694.784 989.866667s248.149333-111.274667 248.149333-248.149334-111.274667-248.149333-248.149333-248.149333z m94.72 298.837333h-94.72c-18.602667 0-33.450667-15.018667-33.450667-33.450666v-109.056c0-18.602667 15.018667-33.450667 33.450667-33.450667 18.602667 0 33.450667 15.018667 33.450667 33.450667v75.434666h61.269333c18.602667 0 33.450667 15.018667 33.450667 33.450667 0.170667 18.602667-14.848 33.621333-33.450667 33.621333z" fill="currentColor" p-id="10546"></path>
            </svg>
        `
      }
    }
  }
}
</script>
