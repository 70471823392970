import JsPdf from 'jspdf'
import html2canvas from 'html2canvas'

const A4_WIDTH = 572.28
const A4_HEIGHT = 841.89
const MAX_HEIGHT = 8000 // 最大打印高度

class PdfLoader {
  constructor (ele, pdfFileName, splitClassName) {
    this.ele = ele
    this.pdfFileName = pdfFileName
    this.splitClassName = splitClassName
  }

  async outPdf () {
    return new Promise((resolve) => {
      // 计算a4纸一页可以展示的高度
      const pageHeight = this.ele.offsetWidth / A4_WIDTH * A4_HEIGHT
      // 1.获取不能被分割的元素
      const nodeList = document.querySelectorAll(`.${this.splitClassName}`)
      const eleBound = this.ele.getBoundingClientRect()
      for (let i = 0; i < nodeList.length; i++) {
        const node = nodeList[i]
        const nodeBound = node.getBoundingClientRect()
        // 2.通过判断node的最上面和最下面是否在同一页进行跨页判断
        // ceil 向上取整
        const nodeTopPage = Math.ceil((nodeBound.top - eleBound.top) / pageHeight) // 计算node在第几页
        const nodeBottomPage = Math.ceil((nodeBound.bottom - eleBound.top) / pageHeight) // 计算node的结尾在第几页
        if (nodeTopPage !== nodeBottomPage) {
          // 3.在node前插入空白页把node挤下去
          const _H = nodeTopPage * pageHeight - (nodeBound.top - eleBound.top)
          const isTableRow = node.classList.contains('ant-table-row')
          let newNodeStr = ''
          if (isTableRow) {
            // 插入表头
            const nodeParent = node.parentNode.previousSibling.innerHTML.replaceAll('<tr>', '<tr class="outPdf-thead emptyDiv">')
            let tdCount = node.children.length || 0
            let tdStr = ''
            while (tdCount > 0) {
              tdStr += '<td></td>'
              tdCount--
            }
            newNodeStr = `
              <tr class="ant-table-row ant-table-row-level-0 emptyDiv"
                style="height: ${_H + 10}px; background: #fff; color: #000000d9;"
              >
                ${tdStr}
              </tr>
              ${nodeParent}
            `
          } else {
            newNodeStr = `
              <div class="emptyDiv" style="height: ${_H + 20}px; background: #fff;"></div>
            `
          }
          node.insertAdjacentHTML('beforebegin', newNodeStr)
        }
      }
      this.getPdf(resolve)
    })
  }

  async getPdf (resolve) {
    const pdf = new JsPdf('p', 'pt', 'a4')
    let totalHeight = this.ele.offsetHeight
    let index = 0
    const reportBody = this.ele.children[0]
    let offset = 0
    while (totalHeight > 0) {
      let pages = 0
      await html2canvas(this.ele, {
        height: this.ele.offsetHeight > MAX_HEIGHT ? MAX_HEIGHT : this.ele.offsetHeight,
        width: this.ele.offsetWidth,
        useCORS: true,
        scale: 2,
        backgroundColor: '#fff'
      }).then(canvas => {
        const contentWidth = canvas.width
        const contentHeight = canvas.height
        const pageHeight = contentWidth / A4_WIDTH * A4_HEIGHT
        let leftHeight = contentHeight // 未生成的页面高度
        let position = 0
        const imgWidth = A4_WIDTH
        const imgHeight = A4_WIDTH / contentWidth * contentHeight
        const pageData = canvas.toDataURL('image/jpeg', 1.0)
        if (leftHeight < pageHeight) {
          pages++
          pdf.addImage(pageData, 'JPEG', 10, 0, imgWidth, imgHeight)
        } else {
          while (leftHeight > 0) {
            pages++
            pdf.addImage(pageData, 'JPEG', 10, position, imgWidth, imgHeight)
            leftHeight -= pageHeight
            position -= A4_HEIGHT
            if (leftHeight > 0) {
              pdf.addPage()
            }
          }
        }
        totalHeight = totalHeight > MAX_HEIGHT ? totalHeight - MAX_HEIGHT + (MAX_HEIGHT - (pages - 1) * pageHeight / 2) : totalHeight - MAX_HEIGHT
        index++
        this.ele.style.height = totalHeight + 'px'
        offset = offset + (MAX_HEIGHT - (pages - 1) * pageHeight / 2)
        reportBody.style.marginTop = `-${index * MAX_HEIGHT - offset}px`
      })
    }
    reportBody.style.marginTop = '0px'
    this.ele.style.height = 'auto'
    pdf.save(this.pdfFileName + '.pdf')
    const emptyNodes = document.querySelectorAll('.emptyDiv')
    for (let i = 0; i < emptyNodes.length; i++) {
      const emptyNode = emptyNodes[i]
      emptyNode.remove() // 从dom树移除
    }
    resolve()
  }
}

export default PdfLoader
